import axios from "axios";
// import { toast } from "reac";
import toast, { Toast } from "react-hot-toast";
const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 60000,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": process.env.REACT_APP_API_URL,
  },
  timeoutErrorMessage: "Timeout Please Check your network and try again",
});
instance.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    console.log(error);

    Promise.reject(error);
  }
);
instance.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    if (error.code === "ECONNABORTED") {
      toast.error(error.message);
    }
    console.log(error);
    return Promise.reject(error?.response?.data);
  }
);
export default instance;
