import React, { useEffect, useRef, useState } from "react";
import CardLayout from "../components/card/CardLayout";
import CardContent from "../components/card/CardContent";
import SCN from "../assets/SupplyChainNuggets.jpeg";
import SCC from "../assets/SupplyChainConsulting.jpeg";
import contact from "../assets/contact.png";
import { Fade, Zoom } from "react-reveal";
import Tada from "react-reveal/Tada";
import { useNavigate } from "react-router-dom";
import Landing from "../assets/LandingPage.jpeg";
import SCMNetworkPic from "../assets/SCMNetworkPic.jpeg";
import arrowRight from "../assets/arrow-right.svg";
import axios from "../utils/axios";

const LandingPage = () => {
  const [isIntersecting, setIsIntersecting] = useState(false);
  const ref = useRef(null);
  const cardRef = useRef(null);
  const navigate = useNavigate();
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsIntersecting(entry.isIntersecting);
      }
      // { rootMargin: "-300px" }
    );
    console.log(isIntersecting);
    observer.observe(ref.current);
    observer.observe(cardRef.current);

    return () => observer.disconnect();
  }, [isIntersecting]);
  const handleClick = () => {
    // Scroll to the top of the page
    window.scrollTo(0, 0);
  };
  return (
    <>
      <div className="flex flex-col items-center w-full">
        <div className="w-full h-[970px] rounded-b-[100px] relative max-[500px]:h-[700px]">
          <div className="w-full h-screen text-center rounded-b-[100px] flex flex-col items-center pt-10 px-0">
            <Fade left delay={1000}>
              <h1 className="text-[#005593] text-[115px] font-bold max-[500px]:text-[40px]">
                Welcome to Harnessville
              </h1>
            </Fade>
            <Fade right delay={1000}>
              <p className="text-[#005593] text-[30px] font-medium w-[50vw] max-[500px]:w-full max-[500px]:text-[20px] max-[500px]:my-5">
                Your Destination for Supply Chain Career Development and
                Consulting
              </p>
            </Fade>

            <Zoom delay={1000}>
              <img
                src={Landing}
                alt=""
                className="w-[75%] h-[680px] absolute -bottom-[4%] object-cover rounded-[20px] max-[500px]:w-[400px] max-[500px]:h-[350px] max-[500px]:bottom-[7%] max-[500px]:px-2"
              />
            </Zoom>
          </div>
        </div>
        <div
          ref={ref}
          className="w-full h-full pt-64 flex flex-col items-center  px-20 max-[500px]:px-0 max-[500px]:py-0 "
        >
          <Fade left>
            <div className="text-center">
              <h2 className="text-[#005593] text-[50px] font-extrabold">
                Knowledge Center
              </h2>
            </div>
          </Fade>
          <div
            ref={cardRef}
            className="grid grid-cols-3 justify-items-center bg-[#FFF] w-full h-[575px] px-20 py-10 rounded-[30px] max-[500px]:h-full max-[500px]:px-5 max-[500px]:py-0 max-[500px]:grid-cols-1 max-[500px]:mt-20 max-[500px]:gap-y-10"
          >
            <Fade left>
              <CardLayout src={SCMNetworkPic} home>
                <CardContent
                  title={"Career Development"}
                  onClick={() => {
                    navigate("/store/student");
                    handleClick();
                  }}
                />
              </CardLayout>
            </Fade>
            <Fade top>
              <CardLayout src={SCC} home>
                <CardContent
                  title={"Professional Consulting"}
                  onClick={() => {
                    navigate("/store/student");
                    handleClick();
                  }}
                />
              </CardLayout>
            </Fade>
            <Fade right>
              <CardLayout src={SCN} home>
                <CardContent
                  title={"Supply Chain Nuggets"}
                  onClick={() => {
                    navigate("/store/student");
                    handleClick();
                  }}
                />
              </CardLayout>
            </Fade>
          </div>
          <img
            src={SCN}
            alt="SCMNetworkPic"
            className="mt-10 w-[85%] rounded-[10px] mx-auto"
          />
        </div>
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/5zhQP2NjU-M"
          title="YouTube video player"
          frameBorder="0"
          className="w-[75%] h-[600px] mt-[6rem] max-[500px]:w-[85%] max-[500px]:h-[200px]"
          allow="accelerometer; 
                autoplay; 
                clipboard-write; 
                encrypted-media; 
                gyroscope; 
                picture-in-picture; 
                web-share"
          allowFullScreen
        ></iframe>
        <div className="bg-[#FFF] w-full h-[120vh] max-[500px]:h-screen px-36 pt-24 max-[500px]:px-0 max-[500px]:mt-0">
          <div className="bg-[#EEE] h-[100vh] rounded-[50px] w-full flex flex-col items-center p-10 relative">
            <div className="text-center text-[#005593] w-[45vw] max-[500px]:w-full">
              <Tada>
                <h5 className="text-[30px] font-medium max-[500px]:text-[25px]">
                  Contact us
                </h5>
                <h2 className="text-[60px] font-bold max-[500px]:text-[30px]">
                  Let us know what you need
                </h2>
              </Tada>
            </div>
            <div className="w-full flex items-center mt-9 max-[500px]:flex-wrap">
              <div className="w-[108px] h-[72px] object-contain" />
              <div className="w-[60vw] max-[500px]:w-full max-[500px]:mt-7">
                {" "}
                <button
                  onClick={() => {
                    navigate("/contact");
                    handleClick();
                  }}
                  style={{
                    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                  }}
                  className="w-[200px] h-[60px] p-3 bg-white rounded-[10px] flex justify-between items-center self-end mx-auto"
                >
                  {" "}
                  <span className="text-[#005593] text-[20px] font-semibold">
                    Contact
                  </span>
                  <img src={arrowRight} alt="" />
                </button>
              </div>
            </div>
            <img src={contact} alt="" className="absolute -bottom-[12%]" />
          </div>
        </div>
      </div>
    </>
  );
};

export default LandingPage;