import "./App.css";
import { RouterProvider } from "react-router-dom";
import { AppContextProvider } from "./context/AppContext";
import { useEffect } from "react";

function App({ router }) {
  return (
    <>
      <AppContextProvider>
        <RouterProvider router={router} />
      </AppContextProvider>
    </>
  );
}

export default App;
