import React from "react";

const CardContent = ({ price, title, onClick }) => {
  return (
    <div
      className={`w-full rounded-[15px] bg-[#FFF] h-[170px] items-center flex flex-col justify-between p-10 ${
        !price ? "py-30" : "py-20"
      }`}
    >
      <div className="pb-4">
        <h4 className="text-[#005593] text-[18
        ]px] font-bold">{title}</h4>
        {price && (
          <p className="text-[#005593] text-[20px] font-medium">{price}</p>
        )}
      </div>
      <button
        onClick={onClick}
        className="w-[153px] h-[38px] mr-14 rounded-[10px] border border-[#EEDFD2] bg-[(217, 217, 217, 0.00)] text-[#005593] text-[15px] font-medium"
      >
        View
      </button>
    </div>
  );
};

export default CardContent;
