import React, { useState } from "react";
import axios from "../utils/axios";
import useApp from "../context/AppContext";
import toast from "react-hot-toast";
// import ellipse1 from "../assets/Ellipse1.svg";
const ContactUsPage = () => {
  const [contactFormData, setContactFormData] = useState({});
  const { isLogging, setIsLogging } = useApp();

  const { fullname, message, email, category } = contactFormData;
  const data = {
    fullname,
    message,
    email,
    category,
  };

  const handleContactForm = async (e) => {
    e.preventDefault();
    if (!fullname || !message || !email || !category) {
      toast.error("Provide the Informations");
      return;
    }
    console.log(data);
    setIsLogging(true);
    try {
      const res = await axios.post(
        "/api/v1/feedback/sendFeedback",
        JSON.stringify(data)
      );
      console.log(res);
      if (res?.success) {
        toast.success(res?.message);
      }
    } catch (error) {
      toast.error(
        error?.response?.data?.message ||
          error?.response?.data?.error ||
          "Only community members who have purchased a product can get in touch."
      );
      console.log(error);
    } finally {
      setIsLogging(false);
    }
  };

  const handleOnChange = (e, inputType) => {
    switch (inputType) {
      case "fullname":
        setContactFormData({
          ...contactFormData,
          fullname: e.target.value,
        });
        break;
      case "message":
        setContactFormData({
          ...contactFormData,
          message: e.target.value,
        });
        break;
      case "email":
        setContactFormData({
          ...contactFormData,
          email: e.target.value,
        });
        break;
      case "category":
        setContactFormData({
          ...contactFormData,
          category: e.target.value,
        });
        break;

      default:
        break;
    }
  };

  const categoryEnum = [
    {
      id: 1,
      name: "Student or Prospective Student",
      value: "Student",
    },
    {
      id: 1,
      name: "Early Career Professional",
      value: "EarlyCareerProfessional",
    },
    {
      id: 1,
      name: "Mid Career Professional",
      value: "MidCareerProfessional",
    },
  ];

  return (
    <>
      <div className=" px-16 mt-24 max-[500px]:px-0 max-[500px]">
        {/* <img
          src={ellipse1}
          alt=""
          className="absolute -top-[20%] right-[5%] w-[800px] max-[500px]:top-[10%] max-[500px]:w-[250px] -z-0"
        /> */}
        <div className="bg-[#EEE] rounded-[20px] w-full h-full flex flex-col items-center py-12 max-[500px]:py-5 max-[500px]:h-full max-[500px]:px-3 relative z-10">
          <div className="text-center">
            <span className="text-[20px] font-semibold max-[500px]:text-[15px]">
              Get in Touch
            </span>
            <h3 className="text-[50px] font-semibold max-[500px]:text-[30px]">
              Contact Us
            </h3>
            <p className="text-[22px] font-medium w-[70vw] max-[500px]:w-full max-[500px]:text-[15px]">
              We are constantly collecting and acting on our users feedback
              regarding additions to our Learning Community. We are happy to
              receive your feedback.
            </p>
          </div>
          <div className="w-[50%] max-[500px]:w-full">
            <form onSubmit={handleContactForm} className="w-full">
              <div className="flex flex-col w-full my-3">
                <label htmlFor="" className="text-[18px] font-medium my-1">
                  Full name
                </label>
                <input
                  value={fullname}
                  onChange={(e) => handleOnChange(e, "fullname")}
                  type="text"
                  name=""
                  id=""
                  className="border border-[#808080] bg-white rounded-[10px] px-5 py-2"
                />
              </div>
              <div className="flex flex-col w-full my-3">
                <label htmlFor="" className="text-[18px] font-medium my-1">
                  Email
                </label>
                <input
                  value={email}
                  onChange={(e) => handleOnChange(e, "email")}
                  type="text"
                  name=""
                  id=""
                  className="border border-[#808080] bg-white rounded-[10px] px-5 py-2 outline-none"
                />
              </div>
              <div className="flex flex-col w-full my-3">
                <label htmlFor="" className="text-[18px] font-medium my-1">
                  Category
                </label>
                <select
                  value={category}
                  onChange={(e) => handleOnChange(e, "category")}
                  className="border border-[#808080] bg-white rounded-[10px] px-5 py-2"
                >
                  <option value={""}>Select Option</option>
                  {categoryEnum.map((category, index) => {
                    return (
                      <option key={index} value={category.value}>
                        {category.name}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="flex flex-col w-full h-[200px] my-3">
                <label htmlFor="" className="text-[18px] font-medium my-1">
                  Message
                </label>
                <textarea
                  value={message}
                  onChange={(e) => handleOnChange(e, "message")}
                  className="border border-[#808080] bg-white rounded-[10px] px-5 py-2 h-full"
                ></textarea>
              </div>
              <div className="text-center">
                <button className="w-[345px] h-[45px] bg-[#005593] text-white font-medium rounded-[10px] text-[20px] my-9 max-[500px]:w-full max-[500px]:my-5 max-[500px]:text-[15px]">
                  {isLogging ? "Loading......" : "Submit"}
                </button>
                <p className="text-[18px] font-medium max-[500px]:text-[12px]">
                  Note: Community members who have purchased a product can get
                  in touch immediately
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUsPage;