import React from "react";
import { Fade } from "react-reveal";

const CardLayoutTwo = ({ children, imgClass, src, home, title }) => {
  return (
    <>
      {/* <Fade left delay={60}> */}
      <p className="text-center text-[27px] max-[500px]:text-[17px] font-bold capitalize h-fit mb-1">
        {title ? title : "Break into Supply chain"}
      </p>
      {/* </Fade> */}
      <div
        className={`bg-[#0054b430] ${
          home ? "w-[918px] h-[420px]" : "w-[500px] h-[550px]"
        } p-4 flex flex-col items-center justify-between rounded-[20px] pt-12 max-[500px]:w-full`}
      >
        <img
          src={src}
          alt=""
          className={
            imgClass
              ? imgClass
              : "w-[250px] h-[250px] bg-white rounded-full object-contain"
          }
        />
        {children}
      </div>
    </>
  );
};

export default CardLayoutTwo;
