import React from "react";
import { Outlet } from "react-router-dom";
import NavBar from "./NavBar";
import Footer from "./Footer";

const ClientPageLayout = () => {
  return (
    <>
      {/* <section className="w-full h-full fixed overflow-scroll"> */}
      <NavBar />
      {/* <section className="w-full"> */}
      <>
        <Outlet />
      </>
      {/* </section> */}
      <Footer />
      {/* </section> */}
    </>
  );
};

export default ClientPageLayout;
