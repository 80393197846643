import React, { useEffect, useMemo, useState } from "react";
import CardLayoutTwo from "../components/card/CardLayoutTwo";
import CardContent from "../components/card/CardContent";
import { useNavigate, useParams } from "react-router-dom";
import { getTitle } from "../utils/title";
import { Fade, Zoom } from "react-reveal";
import SCC from "../assets/SupplyChainConsulting.jpeg";
import useApp from "../context/AppContext";

const StorePageThree = () => {
  const navigate = useNavigate();
  const { params } = useParams();
  const { categoriesData, isLoading } = useApp();
  const [studentJourney, setStudentJourney] = useState();
  const [breakSupply, setBreakSupply] = useState();
  const validParams = useMemo(() => ["mid"], []);
  console.log(params, getTitle(params));
  useEffect(() => {
    if (!validParams.includes(params)) {
      throw new Error("This Route does not exist");
    }
  }, [params, validParams]);
  useEffect(() => {
    const validBreak = categoriesData?.find((category, index) => {
      // console.log(category);
      return category?.typeOfCategory?.includes("Break");
    });
    const validStudent = categoriesData?.find((category, index) => {
      // console.log(category);
      return category?.typeOfCategory?.includes("Student");
    });
    console.log(validStudent, validBreak);
    setBreakSupply(validBreak);
    setStudentJourney(validStudent);
  }, [categoriesData, validParams]);
  const handleClick = () => {
    // Scroll to the top of the page
    window.scrollTo(0, 0);
  };
  return (
    <>
      <div className="w-full bg-white h-full px-16 pt-3 max-[500px]:px-0">
        {/* <div className="w-full pl-0 max-[500px]:p-0 max-[500px]:h-[100%] relative -z-0"></div> */}
        <div className="w-full flex justify-center items-center">
          <div className="grid py-5 justify-items-center max-[500px]:grid-cols-1  max-[500px]:px-3 grid-cols-2 gap-5">
            <Zoom delay={60}>
              <CardLayoutTwo
                title={"Elevate Your Supply Chain Career"}
                src={SCC}
              >
                <CardContent
                  title={"Career Development"}
                  // subtitle={"Sweet in the middle for days"}
                  onClick={() => {
                    navigate(`/detail/${studentJourney?._product?.[0]?._id}`);
                    handleClick();
                  }}
                />
              </CardLayoutTwo>
              <CardLayoutTwo src={SCC}>
                <CardContent
                  title={"Career Development"}
                  // subtitle={"Sweet in the middle for days"}
                  onClick={() => {
                    navigate(`/breakdetail/${breakSupply?._product?.[0]?._id}`);
                    handleClick();
                  }}
                />
              </CardLayoutTwo>
            </Zoom>
          </div>
        </div>
      </div>
    </>
  );
};

export default StorePageThree;