export function getTitle(params) {
  switch (params) {
    case "student":
      return "Student or Prospective Student";
    case "early":
      return "Early Career Professional";
    case "mid":
      return "Mid Career Professional";
    default:
      return "Error Page";
    //   break;
  }
}
