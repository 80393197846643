import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import axios from "../utils/axios";
import toast from "react-hot-toast";
// import axios from "axios";
const AppContext = createContext({
  isLoading: false,
  isLogging: false,
  allProductsData: [],
  singleProductData: {},
  setSingleProductId: () => {},
  setIsLogging: () => {},
});

export function AppContextProvider({ children }) {
  const [isLoading, setIsLoading] = useState(false);
  const [isLogging, setIsLogging] = useState(false);
  const [singleProductData, setSingleProductData] = useState({});
  const [singleProductId, setSingleProductId] = useState("");
  const [allProductsData, setAllProductsData] = useState();
  const [categoriesData, setCategoriesData] = useState();
  const pro = localStorage.getItem("productId");
  console.log(pro);
  useEffect(() => {
    const fetchAllProductsData = async () => {
      setIsLoading(true);
      try {
        const res = await axios.get("/api/v1/product/allproduct");

        console.log(res);
        setAllProductsData(res.products);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchAllProductsData();
  }, []);
  useEffect(() => {
    const fetchSingleProductData = async () => {
      try {
        const res = await axios.get(`/api/v1/product/${singleProductId}`);

        console.log(res);
        if (res.success) {
          setSingleProductData(res.product);
        }
      } catch (error) {
        console.log(error);
      } finally {
      }
    };
    singleProductId && fetchSingleProductData();
  }, [singleProductId]);
  useEffect(() => {
    const fetchCategoriesData = async () => {
      try {
        const res = await axios.get(`/api/v1/admin/allcategories`);

        console.log(res.data);
        if (res.success) {
          setCategoriesData(res.data.categories);
        }
      } catch (error) {
        console.log(error);
      } finally {
      }
    };
    fetchCategoriesData();
  }, [singleProductId]);
  console.log();
  const handleSessionPayment = useCallback(
    async (sessionId) => {
      console.log(pro, "product idddddddd");
      try {
        const res = await axios.post(`/api/v1/payment/verify/${pro}/${sessionId}`);
        console.log(res, "payment");
        if (res.success) {
          toast.success("Thank you for choosing Harnessville!", {
            duration: 1000,
          });
          // Additional logic if needed
        }
      } catch (error) {
        console.error(error);
        toast.error("An Error Occurred");
      }
    },
    [pro]
  );

  useEffect(() => {
    const sessionId = localStorage.getItem("sessionId");
    const ProductId = localStorage.getItem("productId");
    if (sessionId && ProductId) {
      handleSessionPayment(sessionId);
      localStorage.removeItem("sessionId");
      localStorage.removeItem("ProductId");
    }
  }, [handleSessionPayment]);

  return (
    <AppContext.Provider
      value={{
        allProductsData,
        singleProductData,
        isLoading,
        setSingleProductId,
        isLogging,
        setIsLogging,
        categoriesData,
      }}
    >
      {children}
    </AppContext.Provider>
  );
}

export default function useApp() {
  return useContext(AppContext);
}