import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Toaster } from "react-hot-toast";
import { createBrowserRouter } from "react-router-dom";
import ClientPageLayout from "./layout/ClientPageLayout";
import LandingPage from "./pages/LandingPage";
import AboutUsPage from "./pages/AboutUsPage";
import ContactUsPage from "./pages/ContactUsPage";
import DetailPage from "./pages/DetailPage";
import StorePage from "./pages/StorePage";
import TermsOfService from "./pages/TermsOfServicePage";
import StorePageTwo from "./pages/storePageTwo";
import StorePageThree from "./pages/storePageThree";
import SecondDetailPage from "./pages/SecondDetailPage";

const router = createBrowserRouter([
  {
    path: "/",
    element: <ClientPageLayout />,
    children: [
      { index: true, element: <LandingPage /> },
      { path: "/about", element: <AboutUsPage /> },
      { path: "/terms", element: <TermsOfService /> },
      { path: "/store/:params", element: <StorePage /> },
      { path: "/storeTwo/:params", element: <StorePageTwo /> },
      { path: "/storeThree/:params", element: <StorePageThree /> },
      { path: "/detail/:id", element: <DetailPage /> },
      { path: "/breakdetail/:id", element: <SecondDetailPage /> },
      { path: "/contact", element: <ContactUsPage /> },
    ],
  },
]);
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Toaster />
    <App router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
