import React from "react";
import { useNavigate } from "react-router-dom";
import { Fade } from "react-reveal";
import arrowRight from "../assets/arrow-right.svg";
const TermsOfService = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    // Scroll to the top of the page
    window.scrollTo(0, 0);
  };
  return (
    <>
      <div className="w-full">
        <div className="w-full bg-[] rounded-b-[80px] max-[500px]:h-[300px] mb-16 flex flex-col items-start pt-10 relative z-0 max-[500px]:px-5 px-16">
          <Fade left delay={700}>
            <h1 className="text-[40px] font-bold max-[500px]:text-[30px]">
              Terms of Service
            </h1>
          </Fade>
          <Fade right delay={1000}>
            <p className="text-[25px] font-medium w-[80vw] text-start max-[500px]:text-[18px] max-[200px]:w-full">
              These Terms of Service are a binding contract between you and{' '}
              <a
                href="https://www.harnessville.com"
                className="text-blue-500 underline"
              >
                harnessville.com
              </a>{' '}
              (including but not limited to its Author) hereafter referred to as
              “harnessville”, “we”, “us”, “our”.) By using harnessville and its
              products, you agree to all of these Terms of Service, all of which
              will remain in effect while you use harnessville or its products.
            </p>
          </Fade>
        </div>

        <div className="px-16 pt-8 max-[500px]:px-5">
          <div className="mb-20">
            <h2 className="text-[40px] font-semibold max-[500px]:text-[30px]">
              Products, Sale, and Downloads
            </h2>
            <p className="text-[25px] font-medium mt-3 max-[500px]:text-[20px]">
              All sale and downloads of products from harnessville are final.
              You agree that you shall not receive any refund nor are you
              entitled to a refund for digital products purchased or downloaded
              from harnessville. Owing to the nature of digital products and
              downloads, refunds cannot be processed.
            </p>
          </div>
          <div className="mb-16">
            <h2 className="text-[40px] font-semibold max-[500px]:text-[30px]">
              Intellectual Property and Copyright Infringement
            </h2>
            <p className="text-[25px] font-medium mt-3 max-[500px]:text-[17px]">
              We respect the intellectual property of others and to the best of
              our knowledge, we have employed best practices to ensure proper
              referencing of sources, attribution, and licensing. You understand
              that any reproduction, transfer, resale or sharing of digital
              downloads and products from harnessville is prohibited. No part of
              our guides may be reproduced or utilized by any means including
              electronic, photocopying or cloud storage for the purpose of
              shared use without the express written permission of the author.
              It is a violation of intellectual property rights and a copyright
              infringement to post or upload digital products from harnessville
              to any website, social media, or collaborative workspace. All
              products and downloads are intended for the sole use of the
              original purchaser.
            </p>
          </div>
          <div className="mb-16">
            <h2 className="text-[40px] font-semibold max-[500px]:text-[30px]">
              Indemnification
            </h2>
            <p className="text-[25px] font-medium mt-3 max-[500px]:text-[17px]">
              To the fullest extent acceptable by applicable law, you agree to
              indemnify and hold harnessville (including its author) harmless
              from and against any and all claims, damages and liabilities
              (including expenses, loses and attorney fees) arising from the use
              of harnessville’s products.
            </p>
          </div>
          <div className="mb-16">
            <h2 className="text-[40px] font-semibold max-[500px]:text-[30px]">
              Intellectual Property and Copyright Infringement
            </h2>
            <p className="text-[25px] font-medium mt-3 max-[500px]:text-[17px]">
              We respect the intellectual property of others and to the best of
              our knowledge, we have employed best practices to ensure proper
              referencing of sources, attribution, and licensing. You understand
              that any reproduction, transfer, resale or sharing of digital
              downloads and products from harnessville is prohibited. No part of
              our guides may be reproduced or utilized by any means including
              electronic, photocopying or cloud storage for the purpose of
              shared use without the express written permission of the author.
              It is a violation of intellectual property rights and a copyright
              infringement to post or upload digital products from harnessville
              to any website, social media, or collaborative workspace. All
              products and downloads are intended for the sole use of the
              original purchaser.
            </p>
          </div>
          <div className="mb-16">
            <h2 className="text-[40px] font-semibold max-[500px]:text-[30px]">
              Violation of Terms of Service
            </h2>
            <p className="text-[25px] font-medium mt-3 max-[500px]:text-[17px]">
              Failure to abide by any and all of the aforementioned Terms of
              Service shall constitute a breach of agreement.
            </p>
          </div>
          <div className="mb-16">
            <h2 className="text-[40px] font-semibold max-[500px]:text-[30px]">
              Privacy Policy
            </h2>
            <p className="text-[25px] font-medium mt-3 max-[500px]:text-[17px]">
              Harnessville only collects information necessary to complete a
              purchase or download, for the sole purpose of maintaining a client
              base. Harnessville is committed to the highest level of privacy of
              our clients and does not share client information with third
              parties. The information provided during checkout is provided to
              the virtual terminal and payment processing service provider and
              harnessville has no need for this information.
            </p>
          </div>
        </div>

        <div className="px-16 mt-20 max-[500px]:px-5">
          <div className="bg-[#EEE] rounded-[20px] h-[495px] w-full flex flex-col justify-around p-10">
            <h1 className="text-[60px] font-semibold text-center max-[500px]:text-[35px]">
              Get started with Harnessville today
            </h1>
            <button
              onClick={() => {
                navigate("/");
                handleClick();
              }}
              style={{
                boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
              }}
              className="w-[200px] h-[60px] p-3 bg-white rounded-[10px] flex justify-between items-center self-end mx-auto"
            >
              {" "}
              <span className="text-[#005593] text-[20px] font-semibold max-[500px]:text-[17px]">
                Explore
              </span>
              <img src={arrowRight} alt="" />
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default TermsOfService;
