import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Menu from "../assets/menu.svg";
import Arrow from "../assets/arrow-down.svg";
import Logo from "../assets/Logo.jpg";

const NavBar = () => {
  const location = useLocation();
  const [isNavBarOpen, setIsNavBarOpen] = useState(false);
  const [isToggleDropDown, setIsToggleDropDown] = useState(false);
  const menuRef = useRef(null);
  const linksRef = useRef(null);
  const handleClick = () => {
    // Scroll to the top of the page
    window.scrollTo(0, 0);
  };

  const handleNavBar = () => {
    setIsNavBarOpen(!isNavBarOpen);
  };
  const handleNavBarClose = () => {
    setIsNavBarOpen(false);
  };
  const dropDownHandler = () => {
    setIsToggleDropDown(!isToggleDropDown);
  };
  const handleOutClose = useCallback(() => {
    setIsToggleDropDown(false);
  }, []);
  const handleOutLinkClose = useCallback(() => {
    setIsNavBarOpen(false);
  }, []);

  const handleMouseEnter = () => {
    setIsToggleDropDown(true);
  };

  // useEffect(() => {
  //   const handleClose = (e) => {
  //     if (menuRef.current && !menuRef.current.contains(e.target)) {
  //       handleOutClose();
  //     }
  //   };

  //   const handleLinkClose = (e) => {
  //     if (
  //       linksRef.current &&
  //       !linksRef.current.contains(e.target) &&
  //       e.target.id !== "navbutton"
  //       // e.target.id !== "links"
  //     ) {
  //       handleOutLinkClose();
  //       console.log(e.target.id);
  //     }
  //   };

  //   if (isNavBarOpen) {
  //     document.addEventListener("click", handleLinkClose, true);
  //   }

  //   document.addEventListener("click", handleClose, true);

  //   return () => {
  //     document.removeEventListener("click", handleClose, true);
  //     document.removeEventListener("click", handleLinkClose, true);
  //   };
  // }, [handleOutClose, handleOutLinkClose, isNavBarOpen]);

  const handleMouseLeave = () => {
    setIsToggleDropDown(false);
  };
  return (
    <nav
      className={`flex px-16 py-6 justify-between ${
        location.pathname !== "/" && location.pathname !== "/about"
          ? "bg-white"
          : "bg-[]"
      } max-[500px]:px-5  max-[500px]:flex-col h-full items-center`}
    >
      <div className="flex justify-between items-start max-[500px]:w-full max-[500px]:items-center">
        <img
          src={Logo}
          alt="Harnessville"
          className="w-[80px] h-[80px] max-[500px]:w-[40px] max-[500px]:h-[40px]"
        />
        {/* <button > */}
        <img
          src={Menu}
          alt=""
          className="max-[500px]:block hidden cursor-pointer"
          id="navbutton"
          type="button"
          onClick={handleNavBar}
        />
        {/* </button> */}
      </div>
      <div
        onClick={() => {
          handleOutClose();
        }}
        ref={linksRef}
        id="links"
        className={`w-[40%] max-[500px]:w-full z-10 ${
          isNavBarOpen ? "max-[500px]:h-full" : "max-[500px]:h-[20px]"
        }   overflow-hidden `}
      >
        <ul className={`max-[500px]:text-left h-full`}>
          <li className="inline-block mx-4 max-[500px]:my-7 max-[500px]:block">
            <NavLink
              onClick={() => {
                handleClick();
                handleNavBarClose();
              }}
              to={"/"}
              className="text-[#005593] text-[20px] font-normal"
            >
              Home
            </NavLink>
          </li>
          <li className="inline-block mx-4 max-[500px]:my-7 max-[500px]:block">
            <NavLink
              onClick={() => {
                handleClick();
                handleNavBarClose();
              }}
              to={"/about"}
              className="text-[#005593] text-[20px] font-normal"
            >
              About
            </NavLink>
          </li>
          <div
            onClick={(e) => {
              // handleOutClose();
              e.stopPropagation();
            }}
            id="menu-list"
            ref={menuRef}
            className="inline-block "
          >
            <li className="inline-block mx-4 max-[500px]:my-0 max-[500px]:block">
              {/* {location.pathname === "/store" ? ( */}
              <button
                className="dropdown-link text-[#005593] text-[20px] font-normal flex justify-between items-center w-[110px]"
                type="button"
                onClick={() => dropDownHandler()}
                onMouseEnter={handleMouseEnter}
              >
                <span>Explore</span>
                <img src={Arrow} className="" alt="arrow-down" />
              </button>
              {/* ) : (
                <NavLink
                  to={"/store"}
                  className="text-[#005593] text-[20px] font-normal"
                >
                  Explore
                </NavLink>
              )} */}
            </li>
            <div
              // onClick={(e) => e.stopPropagation()}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              style={{
                boxShadow: "2px 2px 7px 0px rgba(0, 0, 0, 0.07)",
              }}
              className={`absolute ${
                isToggleDropDown ? "block" : "hidden"
              } w-[180px] h-[135px] bg-white rounded-[10px]`}
            >
              <Link
                onClick={() => {
                  handleOutClose();
                  handleNavBarClose();
                }}
                className="dropdown-link block text-[10px] font-medium border-b border-b-[#EEE] py-4 px-1"
                to="/store/student"
              >
                Student or Prospective Student
              </Link>
              <Link
                onClick={() => {
                  handleOutClose();
                  handleNavBarClose();
                }}
                className="dropdown-link block text-[10px] font-medium border-b border-b-[#EEE] py-4 px-1"
                to="/storeTwo/early"
              >
                Early Career Professional
              </Link>
              <Link
                onClick={() => {
                  handleOutClose();
                  handleNavBarClose();
                }}
                className="dropdown-link block text-[10px] font-medium py-3 px-1"
                to="/storeThree/mid"
              >
                Mid Career Professional
              </Link>
            </div>
          </div>
        </ul>
      </div>
    </nav>
  );
};

export default NavBar;