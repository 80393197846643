import React from "react";
import AboutUs from "../assets/About.jpg";
import { useNavigate } from "react-router-dom";
import { Fade, Zoom } from "react-reveal";
// import bgImage from '../assets/'
import arrowRight from "../assets/arrow-right.svg";
const AboutUsPage = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className="w-full">
        <div className="w-full bg-[] rounded-b-[100px] max-[500px]:h-[300px] h-[400px] flex flex-col items-center pt-10 relative z-0 max-[500px]:px-5">
          <Fade left delay={1000}>
            <h1 className="text-[100px] font-bold max-[500px]:text-[60px]">
              About us
            </h1>
          </Fade>
          <Fade right delay={1000}>
            <p className="text-[30px] font-medium w-[80vw] text-center max-[500px]:text-[14px] max-[200px]:w-full">
              We help you untangle the complexity of choosing a rewarding career
              path as you navigate a world filled with information overload and
              a plethora of options. We do this by providing insightful supply
              chain management career guides and infotainment.
            </p>
          </Fade>
        </div>
        <div className="items-center">
          <Zoom delay={1000}>
            <img
              src={AboutUs}
              alt=""
              className="items-center w-[80%] -bottom-[9%] object-cover rounded-[20px] max-[500px]:w-full max-[500px]:px-4 mx-auto"
            />
          </Zoom>
        </div>
        <div className="px-16 pt-14 max-[500px]:px-5">
          <div className="mb-20">
            <h2 className="text-[40px] font-semibold max-[500px]:text-[30px]">
              Mission
            </h2>
            <p className="text-[25px] font-medium mt-3 max-[500px]:text-[20px]">
              Harnessville’s mission is to organize supply chain management
              career development information by drawing on extensive industry
              experience, and to provide a guide that enables people to make
              intelligent career decisions.
            </p>
          </div>
          <div className="mb-16">
            <h2 className="text-[40px] font-semibold max-[500px]:text-[30px]">
              Founder and Ideation
            </h2>
            <p className="text-[25px] font-medium mt-3 max-[500px]:text-[17px]">
              The idea to start a supply chain management career development
              community fully crystalized during the pandemic, as companies
              struggled to navigate the unprecedented and uncertain environment
              caused by shortages – both workers and raw materials. With a
              combined 10yrs of educational and professional experience, it
              became imperative that I started some type of supply chain
              management crusade to help more people get into this field. I
              personally struggled to find supply chain talent when I led a
              Sourcing (Procurement) team who had a $500 million spend in the
              Semiconductor space. A summary of my background is enumerated
              below for your perusal.
            </p>
          </div>
          <div className="mb-16">
            <h2 className="text-[40px] font-semibold max-[500px]:text-[30px]">
              Education
            </h2>
            <ul className="list-disc list-inside">
  <li className="text-[20px] font-medium mt-9 max-[500px]:text-[15px]">
    Masters in Supply Chain &amp; Logistics Technology - University
    of Houston
  </li>
  <li className="text-[20px] font-medium mt-3 max-[500px]:text-[15px]">
    Masters in Business Administration (MBA) – Kellogg School of
    Management
  </li>
  <li className="text-[20px] font-medium mt-3 max-[500px]:text-[15px]">
    <span className="hover:underline">
      <a
        href="https://www.linkedin.com/in/misanokpe"
        target="_blank"
        rel="noopener noreferrer"
      >
        Linkedin Profile
      </a>
    </span>
  </li>
</ul>

          </div>
          <div className="mb-16">
            <h2 className="text-[40px] font-semibold max-[500px]:text-[30px]">
              Professional Experience
            </h2>
            <ul className="list-disc list-inside">
              <li className="text-[20px] font-medium mt-2 max-[500px]:text-[15px]">
                Sourcing Manager – Automotive & Heavy Equipment Industry
              </li>
              <li className="text-[20px] font-medium mt-2 max-[500px]:text-[15px]">
                Global Sourcing Manager – Industrial & Consumer Power Tool
                Industry
              </li>
              <li className="text-[20px] font-medium mt-2 max-[500px]:text-[15px]">
                Supply and Demand Management – Consumer Electronics & Tech
                Industry
              </li>
            </ul>
            <p className="text-[20px] font-medium mt-4 max-[500px]:text-[15px]">
              I’m not only passionate about supply chain management as a field,
              but I’m also committed to making sure that the decision to pursue
              a career path in supply chain management is less complex and easy
              to understand. My hope is that people embark on a career journey
              that has a good return on investment and is ultimately rewarding
              for them and their families.
            </p>
          </div>
          <div className="mt-16">
            <h2 className="text-center text-[65px] font-extrabold max-[500px]:text-[40px]">
              What We Offer
            </h2>
            <div>
              <h5 className="text-[40px] font-semibold max-[500px]:text-[30px]">
                Starting Your Supply Chain Journey – Student or Prospective
                Student
              </h5>
              <p className="text-[25px] font-medium mt-3 max-[500px]:text-[17px]">
                This guide is tailored to the needs of a person who wants to
                understand the fundamentals of supply chain management and how
                to develop a career path in Supply Chain Management (an industry
                agnostic field). It encompasses the following:
              </p>
              <ul className="list-disc list-inside">
                <p className="text-[25px] font-medium max-[500px]:text-[17px]"></p>
                <li className="text-[20px] font-medium mt-2 max-[500px]:text-[15px]">
                  Educational Requirements, Degree Cost and Program Rankings
                </li>
                <li className="text-[20px] font-medium mt-2 max-[500px]:text-[15px]">
                  Salary Expectations and Return on Investment
                </li>
                <li className="text-[20px] font-medium mt-2 max-[500px]:text-[15px]">
                  Functional areas of Supply Chain Management and Employment
                  Opportunities
                </li>
              </ul>
            </div>

            <div className="mt-20">
              <h5 className="text-[40px] font-semibold max-[500px]:text-[30px]">
              Break Into Supply Chain Management
              </h5>
              <p className="text-[25px] font-medium mt-3 max-[500px]:text-[17px]">
                This guide provides a framework to help anyone at any stage of their professional
                journey break into supply chain management. It includes clear pathways and actionable
                steps to help you transition to supply chain management. It covers the following:
              </p>
              <ul className="list-disc list-inside">
                <p className="text-[25px] font-medium max-[500px]:text-[17px]"></p>
                <li className="text-[20px] font-medium mt-2 max-[500px]:text-[15px]">
                  Transition roadmap, resume template and pathway mapping exercise
                </li>
                <li className="text-[20px] font-medium mt-2 max-[500px]:text-[15px]">
                  Certifications, Conferences, and employment opportunities
                </li>
                <li className="text-[20px] font-medium mt-2 max-[500px]:text-[15px]">
                  Functional areas of Supply Chain Management and salary by roles
                </li>
              </ul>
            </div>
            <div className="mt-20">
              <h5 className="text-[40px] font-semibold max-[500px]:text-[30px]">
                Accelerating Your Supply Chain Career – Early Career
                Professional
              </h5>
              <p className="text-[25px] font-medium mt-3 max-[500px]:text-[17px]">
                This guide is designed for young professionals who are currently
                in supply chain management or looking to switch career paths. It
                provides a roadmap that helps you navigate the different roles
                across supply chain functions. It covers the following:
              </p>
              <ul className="list-disc list-inside">
                <li className="text-[20px] font-medium mt-2 max-[500px]:text-[15px]">
                  Supply Chain functions and how to get your next role
                </li>
                <li className="text-[20px] font-medium mt-2 max-[500px]:text-[15px]">
                  Career trajectory framework
                </li>
                <li className="text-[20px] font-medium mt-2 max-[500px]:text-[15px]">
                  Supply Chain Certifications (relevance, role specific and
                  cost)
                </li>
              </ul>
            </div>
            <div className="mt-20">
              <h5 className="text-[40px] font-semibold max-[500px]:text-[30px]">
                Elevating Your Supply Chain Career – Mid Career Professional
              </h5>
              <p className="text-[25px] font-medium mt-3 max-[500px]:text-[17px]">
                This guide is designed for mid-career professionals who are
                already in supply chain management or considering it as a
                functional change. It provides a career trajectory framework
                with clear and practical strategies to supercharge your supply
                chain career. It encompasses the following:
              </p>
              <ul className="list-disc list-inside">
                <li className="text-[20px] font-medium mt-2 max-[500px]:text-[15px]">
                  Goals & Outcome for different supply chain roles
                </li>
                <li className="text-[20px] font-medium mt-2 max-[500px]:text-[15px]">
                  Lateral Roles and Promotion Targets; what role to take next
                </li>
                <li className="text-[20px] font-medium mt-2 max-[500px]:text-[15px]">
                  Mapping Senior Leadership and Management Pathways
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="px-16 mt-20 max-[500px]:px-5">
          <div className="bg-[#EEE] rounded-[20px] h-[495px] w-full flex flex-col justify-around p-10">
            <h1 className="text-[60px] font-semibold text-center max-[500px]:text-[35px]">
              Get started with Harnessville today
            </h1>
            <button
              onClick={() => navigate("/store")}
              style={{
                boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
              }}
              className="w-[200px] h-[60px] p-3 bg-white rounded-[10px] flex justify-between items-center self-end mx-auto"
            >
              {" "}
              <span className="text-[#005593] text-[20px] font-semibold max-[500px]:text-[17px]">
                Explore
              </span>
              <img src={arrowRight} alt="" />
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUsPage;
