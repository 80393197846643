import React from "react";

const CardLayout = ({ children, imgClass, src, home }) => {
  return (
    <>
      <div
        className={`bg-[#0054b430] ${
          home ? "w-[318px] h-[420px]" : "w-[400px] h-[520px]"
        } p-4 flex flex-col items-center justify-between rounded-[20px] pt-12 max-[500px]:w-full`}
      >
        <img
          src={src}
          alt=""
          className={
            imgClass
              ? imgClass
              : "w-[150px] h-[150px] bg-white rounded-full object-contain"
          }
        />
        {children}
      </div>
    </>
  );
};

export default CardLayout;
