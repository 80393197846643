import React, { useCallback, useEffect, useRef, useState } from "react";
// import book from "../assets/book.png";
import cover from "../assets/BreakIntoSupplyChainMgtCoverPage.png";
import table from "../assets/BreakIntoSupplyChainTableofContent.png";
import preview3 from "../assets/Break.jpeg";
import preview4 from "../assets/BreakIntoSupplyChainMgt4thPreviewPage.png";
import { useNavigate, useParams } from "react-router-dom";
import axios from "../utils/axios";
import cart from "../assets/cart.svg";
import useApp from "../context/AppContext";
import toast from "react-hot-toast";

const SecondDetailPage = () => {
  const navigate = useNavigate();
  const [isImageFullscreen, setIsImageFullscreen] = useState(false);
  const [paymentModal, setPaymentModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [enteredEmail, setEnteredEmail] = useState("");
  const [enteredName, setEnteredName] = useState("");
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [currentPaginationImageIndex, setCurrentPaginationImageIndex] =
    useState(0);
  const productId = useParams();
  const { setSingleProductId, singleProductData, isLogging, setIsLogging } =
    useApp();
  const profileRef = useRef(null);
  function handleClose() {
    setPaymentModal(false);
  }
  // useEffect(() => {
  //   const handleOutClick = (e) => {
  //     if (profileRef.current && !profileRef.current.contains(e.currentTarget)) {
  //       handleClose && handleClose();
  //     }
  //   };
  //   document.addEventListener("click", handleOutClick, true);

  //   return () => {
  //     document.removeEventListener("click", handleOutClick, true);
  //   };
  // }, []);
  const images = [cover, cover, table, preview3, preview4];
  // let filteredImages = images.find
  const openFullscreen = (index) => {
    setIsImageFullscreen(true);

    setCurrentImageIndex(index);
    console.log(index);
  };
  const closeFullscreen = () => {
    setIsImageFullscreen(false);
  };

  const handlePaymentModal = () => {
    setPaymentModal(true);
  };
  console.log(productId);
  useEffect(() => {
    if (productId.id) {
      setSingleProductId(productId.id);
    }
  }, [productId, setSingleProductId]);

  const handlePayment = async (e) => {
    e.preventDefault();
    if (enteredName === " " || enteredEmail === " ") {
      return;
    }

    try {
      const res = await axios.post(
        `/api/v1/payment/pay/${productId.id}`,
        JSON.stringify({ name: enteredName, email: enteredEmail })
      );
      console.log(res);
      if (res.success) {
        localStorage.setItem("sessionId", res.sessionId);
        window.location.href = res.sessionUrl;
      }
    } catch (error) {
      console.log(error);
      toast.error("An Error Occured");
    }
  };

  const handleSessionPayment = useCallback(
    async (sessionId) => {
      try {
        const res = await axios.post(
          `/api/v1/payment/verify/${productId.id}/${sessionId}`
        );
        console.log(res, "payment");
        toast.success("Thank you for choosing Harnessville!");
      } catch (error) {
        console.log(error);
        toast.error("An Error Occured");
      }
    },
    [productId.id]
  );

  useEffect(() => {
    const sessionId = localStorage.getItem("sessionId");
    if (sessionId) {
      handleSessionPayment(sessionId);
      localStorage.removeItem("sessionId");
    }
  }, [handleSessionPayment]);
  function handleNextPage() {
    if (currentPaginationImageIndex < images.length - 1) {
      setCurrentPaginationImageIndex(currentPaginationImageIndex + 1);
    }
    console.log(currentPaginationImageIndex);
  }
  function handlePrevPage() {
    if (currentPaginationImageIndex > 0) {
      setCurrentPaginationImageIndex(currentPaginationImageIndex - 1);
    }
    console.log(currentPaginationImageIndex);
  }
  return (
    <>
      <div className="w-full h-full px-8 max-[500px]:px-3 ">
        <div className="flex w-full mt-8 items-start flex-wrap relative z-0 max-[500px]:flex max-[500px]:flex-col gap-[7.5%]">
          <div className="w-[50%] flex flex-col items-start max-[500px]:w-full">
            <div className="w-full h-[500px] max-[500px]:w-full relative">
              <img
                src={images[0]}
                className="w-full h-full"
                alt=""
                onClick={() => openFullscreen(0)}
              />
            </div>
            <div className="flex flex-wrap justify-between gap-3 w-full">
              {images.slice(1).map((image, index) => (
                <img
                  key={index}
                  src={image}
                  className="w-[150px] h-[150px] object-contain max-[500px]:w-[80px] max-[500px]:h-[80px]"
                  alt=""
                  onClick={() => openFullscreen(index + 1)}
                />
              ))}
              {/* <img
                src={images[1]}
                className="w-[80px] h-[80px] object-cover max-[500px]:w-[80px] max-[500px]:h-[80px]"
                alt=""
                onClick={openFullscreen}
              />
              <img
                src={images[2]}
                className="[80px] h-[80px] object-cover max-[500px]:w-[80px] max-[500px]:h-[80px]"
                alt=""
                onClick={openFullscreen}
              />
              <img
                src={images[3]}
                className="[80px] h-[80px] object-cover max-[500px]:w-[80px] max-[500px]:h-[80px]"
                alt=""
              />
              <img
                src={images[4]}
                className="[80px] h-[80px] object-cover max-[500px]:w-[80px] max-[500px]:h-[80px]"
                alt=""
              /> */}
            </div>
          </div>
          <div className="ml-10 max-[500px]:m-0 max-[500px]:h-[300px] relative max-[500px]:w-full max-[500px]:mt-10 w-[30%]">
            <div className=" absolute -top-5">
              <h3 className="text-[35px] font-semibold max-[500px]:text-[30px] max-[500px]:my-5">
                Break into Supply Chain
              </h3>

              <span className="text-[35px] lg:mt-11 font-semibold block my-20 mt-0 max-[500px]:my-10">
                $49.99
              </span>
              <button
                onClick={handlePaymentModal}
                className="w-[350px] h-[50px] bg-[#005593] flex items-center justify-evenly  px-5 py-7 rounded-[10px]  max-[500px]:w-full max-[500px]:h-[50px]"
              >
                <img src={cart} alt="" className="w-[30px] h-[30px]" />
                <p className="text-white font-semibold text-[25px] text-center mr-16 max-[500px]:ml-10 max-[500px]:text-[20px]">
                  Download
                </p>
              </button>
            </div>
            {/* <p className="text-[25px] font-medium mb-10 max-[500px]:text-[16px]">
              Lörem ipsum reska trenade för att parativ de hikikomori ludode.
            </p> */}
          </div>
        </div>
        <div className="bg-[#EEE] rounded-[20px] w-full h-fit mt-20 px-20 py-24 max-[500px]:px-5 overflow-scroll max-[500px]:py-10 max-[500px]:mt-24">
          <h2 className="text-[45px] font-semibold max-[500px]:text-[30px]">
            Description
          </h2>
          <p className="text-[25px] font-medium mt-5 max-[500px]:text-[18px]">
                This guide provides a framework to help anyone at any stage of their professional
                journey break into supply chain management. It includes clear pathways and actionable
                steps to help you transition to supply chain management. It covers the following:
          </p>
          <ul className="list-disc list-inside">
                <p className="text-[25px] font-medium max-[500px]:text-[17px]"></p>
                <li className="text-[20px] font-medium mt-2 max-[500px]:text-[15px]">
                  Transition roadmap, resume template and pathway mapping exercise
                </li>
                <li className="text-[20px] font-medium mt-2 max-[500px]:text-[15px]">
                  Certifications, Conferences, and employment opportunities
                </li>
                <li className="text-[20px] font-medium mt-2 max-[500px]:text-[15px]">
                  Functional areas of Supply Chain Management and salary by roles
                </li>
              </ul>
        </div>
      </div>
      {isImageFullscreen && (
        <div className="fullscreen-overlay" onClick={closeFullscreen}>
          <img
            src={images?.[currentImageIndex]}
            className="fullscreen-image"
            alt=""
          />
        </div>
      )}
      {paymentModal && (
        <div
          ref={profileRef}
          onClick={handleClose}
          className="fullscreen-overlay max-[500px]:px-3"
        >
          <form
            onClick={(e) => e.stopPropagation()}
            onSubmit={handlePayment}
            className="bg-white w-[50%] h-[450px] rounded-[20px] mt-0 flex flex-col items-center justify-center px-6 max-[500px]:w-full"
          >
            <div className="flex items-center justify-between w-full">
            <h2>Please enter your details</h2>
              <button onClick={handleClose}>Close</button>
            </div>
            <div className="flex flex-col w-full my-6">
              <label
                className={`text-[25px] font-medium ${
                  errorMessage ? "text-red-600" : "text-[#005593]"
                }`}
              >
                Name
              </label>
              <input
                type="text"
                className={`border-2 ${
                  errorMessage ? "border-red-600" : "border-[#005593]"
                } rounded-[10px] w-full px-4 py-2 mt-2`}
                name=""
                autoComplete="new-password"
                value={enteredName}
                onChange={(e) => setEnteredName(e.target.value)}
              />
              <p className="text-[12px] text-red-600">
                {errorMessage ? errorMessage : null}
              </p>
            </div>
            <div className="flex flex-col w-full my-6">
              <label
                className={`text-[25px] font-medium ${
                  errorMessage ? "text-red-600" : "text-[#005593]"
                }`}
              >
                Email
              </label>
              <input
                type="email"
                className={`border-2 ${
                  errorMessage ? "border-red-600" : "border-[#005593]"
                } rounded-[10px] w-full px-4 py-2 mt-2`}
                name=""
                autoComplete="new-password"
                value={enteredEmail}
                onChange={(e) => setEnteredEmail(e.target.value)}
              />
              <p className="text-[12px] text-red-600">
                {errorMessage ? errorMessage : null}
              </p>
            </div>
            <button
              type="submit"
              disabled={isLogging}
              className="w-[50%] bg-[#005593] h-[50px] px-3 py-2 text-white text-[20px] font-medium rounded-[10px] mt-12"
            >
              {isLogging ? "Loading....." : "Get Me"}
            </button>
          </form>
        </div>
      )}
    </>
  );
};

export default SecondDetailPage;