import React from "react";
import { Link } from "react-router-dom";
import Logo from "../assets/Logo.jpg";
import youtube from "../assets/youtube.avif";
import { FaFacebook, FaLinkedin, FaTiktok, FaYoutube } from "react-icons/fa";
const Footer = () => {
  const handleClick = () => {
    // Scroll to the top of the page
    window.scrollTo(0, 0);
  };
  return (
    <footer className="text-[#005593] bg-white w-full mt-72 max-[500px]:mt-52">
      <div className="px-16 flex items-start pb-16 max-[500px]:flex-col max-[500px]:px-5">
        <div>
          <img
            src={Logo}
            alt="Harnessville"
            className="w-[80px] h-[80px] bg-white mt-3"
            style={{ backgroundColor: "white" }}
          />
        </div>
        <div className="mx-auto w-[40vw] flex gap-28 max-[500px]:mx-0 max-[500px]:w-full max-[500px]:gap-5  max-[500px]:my-6 max-[500px]:flex-col">
          <div>
            <h1 className="text-[25px] font-medium max-[500px]:text-[20px]">
              Information
            </h1>
            <ul>
              <li className="my-3">
                <Link
                  onClick={handleClick}
                  to={"/"}
                  className="text-[20px] font-medium max-[500px]:text-[15px]"
                >
                  Home
                </Link>
              </li>
              <li className="my-3">
                <Link
                  onClick={handleClick}
                  to={"/about"}
                  className="text-[20px] font-medium max-[500px]:text-[15px]"
                >
                  About
                </Link>
              </li>
              <li className="my-3">
                <Link
                  onClick={handleClick}
                  to={"/contact"}
                  className="text-[20px] font-medium max-[500px]:text-[15px]"
                >
                  Contact us
                </Link>
              </li>
            </ul>
          </div>
          <div>
            <h1 className="text-[25px] font-medium max-[500px]:text-[20px]">
              Categories
            </h1>
            <ul>
              <li className="my-3">
                <Link className="text-[20px] font-medium max-[500px]:text-[15px]">
                  Career Development
                </Link>
              </li>
              <li className="my-3">
                <Link className="text-[20px] font-medium max-[500px]:text-[15px]">
                  Professional Consulting
                </Link>
              </li>
              <li className="my-3">
                <Link className="text-[20px] font-medium max-[500px]:text-[15px]">
                  Supply Chain Nuggets
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="mx-auto w-[35vw] gap-[5.5rem] max-[500px]:gap-[5rem]  max-[500px]:mx-0 max-[500px]:w-full  max-[500px]:my-5 flex max-[500px]:px-5">
        <Link
          onClick={handleClick}
          to={"/terms"}
          className="  text-[25px] font-medium max-[500px]:my-5 max-[500px]:text-[18px]"
        >
          Privacy Policy
        </Link>
        <Link
          onClick={handleClick}
          to={"/terms"}
          className="  text-[25px] font-medium max-[500px]:text-[18px] max-[500px]:my-5"
        >
          Terms of service
        </Link>
      </div>
      <div className="mx-auto my-[20px] w-[15vw] gap-[5.5rem] max-[500px]:gap-[5rem]  max-[500px]:mx-auto max-[500px]:w-[45vw]  max-[500px]:my-5 flex max-[500px]:px-5">
        <Link to={"https://www.youtube.com/@HarnessVille"}>
          <FaYoutube />
        </Link>
        <Link to={"https://www.tiktok.com/@harnessville?_t=8iodAQPPGwn&_r=1"}>
          <FaTiktok />
        </Link>
      </div>
    </footer>
  );
};

export default Footer;